/* eslint-disable */
import { request } from '@umijs/max';

/** 登录接口 POST /api/login/account */
export async function login(data: API.User.LoginReq) {
  return request<API.Common.BaseResponse<API.User.LoginResp>>('/api/backend/login', {
    method: 'POST',
    data: data,
  });
}


/** 查询用户信息 */
export async function fetchUserInfo(options?: { [key: string]: any }) {
  return request<API.Common.BaseResponse<API.User.UserInfo>>('/api/backend/user', {
    method: 'GET',
    ...(options || {}),
  });
}



class UserApi {
  /**
   * 获取用户列表
   * @param body
   * @returns
   */
  public static async getUserList(data: API.User.UserListReq) {
    return request<API.Common.BaseListResponse<API.User.UserInfo>>(
      '/api/backend/user/list',
      {
        method: 'POST',
        data: data,
      },
    );
  }


  /**
   * 修改用户信息
   * @param body
   * @returns
   */
  public static async updateUser(body: API.User.UserInfo) {
    return request<API.Common.BaseResponse<boolean>>('/api/backend/user', {
      method: 'POST',
      data: body,
    });
  }

  /**
   * 删除用户
   * @param id 用户ID
   * @returns
   */
  public static async deleteUser(id: number) {
    return request<API.Common.BaseResponse<string>>('/api/backend/user/' + id, {
      method: 'DELETE',
    });
  }
}

export default UserApi;
