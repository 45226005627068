import { GithubOutlined } from '@ant-design/icons';
import { DefaultFooter } from '@ant-design/pro-components';
import React from 'react';

const Footer: React.FC = () => {
  return (
    <DefaultFooter
      style={{
        background: 'none',
      }}
      links={[
        {
          key: '',
          title: '姜太公（南京）互联网科技有限公司',
          href: '#',
          blankTarget: false,
        },
       
        {
          key: '2',
          title: '@ copyright 2024',
          href: '#',
          blankTarget: false,
        },
      ]}
    />
  );
};

export default Footer;
