// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import SmileOutlined from '/Users/mac/Documents/HBuilderProjects/fishing-admin/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/SmileOutlined';
import RobotFilled from '/Users/mac/Documents/HBuilderProjects/fishing-admin/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/RobotFilled';
import SketchCircleFilled from '/Users/mac/Documents/HBuilderProjects/fishing-admin/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/SketchCircleFilled';
import ProfileFilled from '/Users/mac/Documents/HBuilderProjects/fishing-admin/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/ProfileFilled';
import MediumCircleFilled from '/Users/mac/Documents/HBuilderProjects/fishing-admin/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/MediumCircleFilled';
import FolderOpenFilled from '/Users/mac/Documents/HBuilderProjects/fishing-admin/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/FolderOpenFilled';
import PayCircleFilled from '/Users/mac/Documents/HBuilderProjects/fishing-admin/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/PayCircleFilled';
import CrownOutlined from '/Users/mac/Documents/HBuilderProjects/fishing-admin/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/CrownOutlined';
import TableOutlined from '/Users/mac/Documents/HBuilderProjects/fishing-admin/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/TableOutlined';
export default { SmileOutlined, RobotFilled, SketchCircleFilled, ProfileFilled, MediumCircleFilled, FolderOpenFilled, PayCircleFilled, CrownOutlined, TableOutlined };
